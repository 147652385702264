@import "../../c_css/a_parameters.scss";

.pricetargetcontainer {
  width: 300px;
  margin: 0px auto;
  font-size: 19px;
  line-height: 37px;
  letter-spacing: 1px;
  position: relative;
}
.pricetargets {
}
.pricetarget {
  width: 50%;
  float: left;
  opacity: 0.5;
  padding: 0px 20px;
  cursor: pointer;
}
.pricetarget.selected {
  color: $cl_primary;
  opacity: 1;
}
.pricetargetulct {
  position: absolute;
}
.pricetargetul {
  width: 50%;
  height: 37px;
  border: solid 2px $cl_accent2_d2;
  border-radius: 5px;
}
.pricetargetul.typ2 {
  /*transform: translate(100%,0);*/
}
.priceperiod_m,
.priceperiod_y {
  font-size: 15px;
  letter-spacing: 1px;
  min-width: 50px;
  display: inline-block;
  width: 150px;
}
.priceperiod_m {
  text-align: right;
}
.priceperiod_y {
  text-align: left;
}
.priceperiod_sw {
  margin: 0px 10px;
  font-size: 20px;
}

.priceperiod_sw {
}

.pricetable {
  display: table;
}

.priceitem {
  position: relative;
  float: left;
  padding: 0px 20px;
  color: black;
}
.pricelock {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: none;
}
.priceitem {
  width: 350px;
  margin:20px 0px;
}
.priceitem.w33 {
  width: 33%;
}
.priceitem.w50 {
  width: 50%;
}

.pricelock .fas {
  font-size: 50px;
  line-height: 50px;
  margin: 10px;
}
.pricelock .text {
  letter-spacing: 1px;
  max-width: 70%;
  font-size: 18px;
  line-height: 25px;
}
.priceitemin {
  position: relative;
  width: 100%;
  float: left;
  border-radius: 5px;
  overflow: hidden;
}
.pricehead {
  padding: 10px;
  text-align: center;
  color: white;
  position: relative;
  float: left;
  width: 100%;
}
.pricename {
  opacity: 0.7;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  float: left;
}
.pricetag {
  width: 100%;
  float: left;
  position: relative;
}
span.pricecur {
  font-size: 25px;
  display: inline-block;
  text-align: right;
}
span.pricepariod {
  font-size: 15px;
  opacity: 0.7;
  width: 60px;
  display: inline-block;
  text-align: left;
}
span.pricevalue {
  font-size: 40px;
  line-height: 50px;
  margin: 0px 5px;
}
.pricetag2 {
  opacity: 0.7;
}
.priceinclude {
  width: 100%; /*padding: 10px;*/
  float: left;
}
.priceinclude ul {
}
.priceinclude li {
  width: 100%;
  float: left;
  line-height: 38px;
  padding: 0px 20px;
}
.priceinclude li.odd {
}
.priceinclude li.even {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.priceinclude li.sponsor {
  line-height: 70px;
  font-size: 30px;
}
.priceinclude li span.fas {
  margin-right: 10px; /*font-size: 18px;*/
  opacity: 0.5;
  width: 30px;
  text-align: center;
}
.priceinclude li .fas.fa-check {
  color: $cl_green;
}
.priceinclude li .fas.fa-times {
  color: $cl_red;
}
.priceinclude li span.text {
  /*opacity: 0.8;*/
  font-size: 15px;
}
.pricebutton {
  width: 100%;
  margin-top: 0px;
  color: white;
  position: relative;
  float: left;
  padding: 13px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

.pricebutton span.text {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  opacity: 0.3;
}
.priceitemin {
  transition: 0.2s;
  -webkit-transition: 0.2s;
  box-shadow: 0px 0px 10px transparentize($cl_code, 0.8);
  cursor: pointer;
}
.priceitemin:hover {
  /*transform: translate(0px,-8px); */
  box-shadow: 0px 0px 20px transparentize($cl_code, 0.6);
}
.priceitemin:hover .pricebutton span {
  opacity: 1;
}

.priceitem.locked .pricelock {
  display: flex;
  display: -webkit-flex;
  color: $cl_code;
  font-weight: bold;
  opacity: 0.7;
}
.priceitem.locked .priceitemin {
  opacity: 0.3;
}
