
.content_item { }

.content_item.itemtype_text {

  i { font-style: italic;}
  strong { font-weight: bold; }
  ul {  padding-left: 20px;
    li { list-style: disc; list-style-position: outside;}
  }
  ol { padding-left: 20px;
    li {list-style: decimal; list-style-position: outside;}
  }
  h1 {  font-size: 35px; }
  h2 {  font-size: 30px; }
  h3 {  font-size: 24px; }
  h4 {  font-size: 19px; }
  h1,h2,h3,h4 {}
  a { color: #2baad9;  }
  input.own {
    font-size: unset;
    line-height: unset;
    font-weight: unset;
  }
  textarea.own {
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    resize: none;
    min-height: 100px;

    border: none;
    outline: none;
  }
  p {
    margin: 5px 0px;
    text-align: justify;
  }
  figure.table table{margin:0px auto;}
}
.content_item.itemtype_code {
  .CodeMirror {
    height: unset !important;
  }
}


.content_edit {
  .content_item.itemtype_text {color:black}
}

.content_rep {
  .content_item.itemtype_text {
    h1,h2,h3,h4 {
      padding: 5px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    pre code {
      display: block;
      padding: 0.5em;
      white-space: break-spaces;
      user-select: text;
    }
    pre + p {margin-top:5px;}
    code:not(.hljs) {  padding: 0em 0.3em;  }
    
    code{
      user-select: text;
      -webkit-touch-callout: text;
      -webkit-user-select: text;
    }
    table{
      td,th{padding:3px;border: solid 1px;min-width: 100px;}
      th{font-weight: bold;}
    }
    p{
      margin:0px;padding:3px 5px;
    }
    
  }

  .content_item.itemtype_dom{
    padding:10px;
    color: black; 
    background-color: white;
    td,th{padding:3px; border:solid 1px #dddddd;min-width: 100px;}
    th{font-weight: bold; text-align: center; padding: 5px;}
    b{font-weight: bold;}
    strong{font-weight: bold;}
    i{font-style: italic;}
    em{font-style: italic;}
    mark{background-color: yellow;color: black;}
    small{font-size: smaller;}
    del{text-decoration: line-through;}
    ins{text-decoration: underline;}
    sub{vertical-align: sub;font-size: smaller;}
    sup {vertical-align: super;font-size: smaller;}
    button{}
  }

}

