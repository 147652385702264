/*
js fidle
1a1d21  header dark + darken
1f2227  code
36393d  grey button 
f3ca63  yellow button 
26aa5a  green button

header: #26363c; -- darken
code bg: #304148
bg       #3b4a51   light

*/

$mobile_width: 900;

$cl_code: #1f2430; //#304148;
$cl_code_d: darken($cl_code, 5%);
$cl_code_sd: darken($cl_code, 2%);
$cl_code_l: lighten($cl_code, 5%);

$cl_bg: $cl_code;
$cl_bg_d: darken($cl_bg, 5%);
$cl_bg_sd: darken($cl_bg, 2%);
$cl_bg_l: lighten($cl_bg, 5%);

$cl_primary: #e5e5e5;
$cl_accent: #2baad9;
$cl_accent_d: darken($cl_accent, 10%);
$cl_accent_d2: darken($cl_accent, 20%);
$cl_accent2: #ffcc66;
$cl_accent2: #ff4c4c; //#ff4c4c;
$cl_accent2_d: darken($cl_accent2, 10%);
$cl_accent2_d2: darken($cl_accent2, 20%);
$cl_accent3: #1c242a;
$cl_accent3_d: darken($cl_accent3, 10%);
$cl_accent3_d2: darken($cl_accent3, 20%);

$cl_grey: #313642;
$cl_grey_d: darken($cl_grey, 5%); //#242833;
$cl_grey_l: lighten($cl_grey, 20%); //#242833;

$cl_red: #f44336;
$cl_green: #4caf50;
$cl_green_l: #bee2c0;
$cl_green_vl: #bee2c0;
$cl_orange: #ffa500; // #e48420 ;
$cl_gold: #ffd700;
$cl_yellow: darken(#ffcc66, 10%);

$cl_error: #d01400;
$bg_error: #f9cec9;
$cl_warning: #a25e01;
$bg_warning: #f5d2a2;
$cl_info: #014275;
$bg_info: #e8f4fd;
$cl_success: #1e4620;
$bg_success: #d5ffd5;

