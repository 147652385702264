@import "../../c_css/a_parameters.scss";

.pl1t1 {
  padding-left: 1px !important;
  padding-top: 1px !important;
}
.pl1 {
  padding-left: 1px !important;
}

.codeboxes {
  .codebox-header {
    background-color: $cl_bg;
    font-size: 14px;
    font-style: italic;
    line-height: 30px;
    padding: 0px 5px;
    height: 30px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.05);
    color: $cl_primary;
  }
  .codebox-iframe {
    background-color: #1f2430;
  }

  .codebox-content > div {
    height: 100% !important;
  }
}

$headersize: 50;

.codeboxes {
  height: unset;
  min-height: 100%;

  .codebox {
    position: relative;
    float: left;
    overflow: hidden;
    border-top: 1px solid $cl_code_d;
    border-left: 1px solid $cl_code_d;
    width: 50%;
    .codebox-content {
      height: calc(100% - 30px);
    }
    min-height: 200px;
    height: 50%;
  }

  .codebox.s05x1:not(.edit),
  .codebox.s05x2:not(.edit) {
    min-height: 100px;
    height: 25%;
  }

  .codebox.s15x1,
  .codebox.s15x2 {
    min-height: 300px;
    height: 75%;
  }

  .codebox.s2x1,
  .codebox.s2x2,
  .codebox.full {
    min-height: 400px;
    height: 100%;
  }

  .codebox.s1x2,
  .codebox.s2x2,
  .codebox.s05x2,
  .codebox.s15x2,
  .codebox.full,
  .codebox.sany {
    width: 100%;
  }
  .codebox.hide {
    display: none;
  }
  .codebox.sany {
    height: unset;
    min-height: unset;
  }
}

@media screen and (max-width: 700px) {
  .codebox {
    width: 100% !important;
  }
  .codebox.s2x1,
  .codebox.s2x2,
  .codebox.full {
    min-height: 200px !important;
    height: 50vh !important;
  }
}
