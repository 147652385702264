@import "../../../c_css/a_parameters.scss";

@mixin box-head {
  background-color: #1f2430;
  font-size: 14px;
  font-style: italic;
  line-height: 40px;
  height: 40px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
  color: #e5e5e5;
  overflow: hidden;
  user-select: none;
}
@mixin box-body {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  height: calc(100% - 40px);
}

#technology_iframe2 {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;

  .headeritem {
    float: left;
    cursor: pointer;
    padding: 0px 10px;
    .hicon {
      margin-right: 5px;
    }
    transition: 0.3s;
    font-size: 13px;
  }
  .headeritem.inactive {
    opacity: 0.4;
  }
  .headeritem.inactive:hover {
    opacity: 1;
  }

  .bodyitem {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .bodyitem.inactive {
  }
  .bodyitem.active {
    z-index: 100;
  }

  #iframe2_cws {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;

    #iframe2_cws_head {
      @include box-head;
    }
    #iframe2_cws_body {
      @include box-body;
    }
  }

  .iframe2_col {
    position: relative;
    float: left;
    width: 50%;
    height: 100%;
    overflow: hidden;
    background-color: #1f2430;
  }
  .iframe2_col_in {
  }
  .iframe2_col_out {
    border-left: 1px solid #151821;
  }

  .codebox {
    width: 100% !important;
    height: 100% !important;
    border: none;
    .codebox-header {
      display: none !important;
    }
    .codebox-content {
      height: calc(100% - 40px);
    }
  }

  #iframe2_iframe {
    position: relative;
    width: 100%;
    float: left;
    background-color: white;
    transition: 0.5s;
    height: 100%;
    height: calc(100% - 40px);
  }

  #iframe2_iframe_head {
    @include box-head;
    display: none;
  }

  #iframe2_info {
    position: absolute;
    bottom: 0px;
    width: 100%;
    float: left;

    overflow-x: hidden;
    overflow-y: auto;
    background-color: #1f2430;
    border-top: 1px solid #151821;
    transition: 0.5s;
    overflow: hidden;
    height: 40px;
  }

  .iframe2_info_split {
    height: 50%;
    width: 100%;
    float: left;
  }
  .iframe2_info_split.border-bottom {
    border-bottom: solid 1px #151821;
  }

  .iframe2_info_head {
    padding-right: 10px;
    @include box-head;
  }
  .iframe2_info_body {
    opacity: 0;
    @include box-body;
  }

  #iframe2_info_close,
  #iframe2_info_open {
    padding: 0px 8px;
    opacity: 0.5;
    cursor: pointer;
  }
  #iframe2_info_close:hover,
  #iframe2_info_open:hover {
    opacity: 1;
  }

  #__test,
  #__console {
    overflow-y: auto;
    overflow-x: hidden;
  }
  #__test_nr,
  #__console_nr {
    background-color: #e5e5e5;
    border-radius: 20px;
    color: black;
    margin: 0px 5px;
    min-width: 16px;
    display: inline-block;
    line-height: 16px;
    text-align: center;
  }

  #__test_head.red {
    color: $cl_red;
    #__test_nr {
      background-color: $cl_red;
    }
  }
}

#technology_iframe2.noInput {
  .iframe2_col_in {
    display: none;
  }
  .iframe2_col_out {
    width: 100%;
  }
  #iframe2_iframe {
    height: 100%;
  }
  #iframe2_info {
    display: none;
  }
}
