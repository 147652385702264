a{color:$cl_primary}
a.hover:hover{color:$cl_accent; span{color:$cl_accent}}


.cl_primary{color:$cl_primary !important}
.cl_accent{color:$cl_accent !important}
.cl_accent_d{color:$cl_accent_d !important}
.cl_accent_d2{color:$cl_accent_d2 !important}
.cl_accent2{color:$cl_accent2 !important}
.cl_accent3{color:$cl_accent3 !important}
.cl_red{color:$cl_red !important}
.cl_green{color:$cl_green !important}
.cl_yellow{color:$cl_yellow !important}
.cl_orange{color:$cl_orange !important}
.cl_gold{color:$cl_gold !important}
.cl_grey{color:$cl_grey !important}
.cl_grey_l{color:$cl_grey_l !important}
.cl_white{color:white !important}
.cl_black{color:black !important}
.cl_code{color:$cl_code !important}
.cl_code_l{color:$cl_code_l !important}
.cl_code_d{color:$cl_code_d !important}
.cl_code_sd{color:$cl_code_sd !important}

.cl_cbh1{color:$cl_yellow !important}

.hcl_primary:hover{color:$cl_primary !important}
.hcl_accent:hover{color:$cl_accent !important}
.hcl_accent_d:hover{color:$cl_accent_d !important}
.hcl_accent_d2:hover{color:$cl_accent_d2 !important}
.hcl_accent2:hover{color:$cl_accent2 !important}
.hcl_accent3:hover{color:$cl_accent3 !important}

.bg{background-color: $cl_bg;}
.bg_d{background-color: $cl_bg_d;}
.bg_sd{background-color: $cl_bg_sd;}
.bg_l{background-color: $cl_bg_l;}
.bg_white{background-color: white;}
.bg_grey{background-color: $cl_grey;}
.bg_code{background-color: $cl_code;}
.bg_code_l{background-color: $cl_code_l;}
.bg_accent{background-color: $cl_accent;}
.bg_accent_d{background-color: $cl_accent;}
.bg_accent_d{background-color: $cl_accent_d;}
.bg_accent_d2{background-color: $cl_accent_d2;}


.bg_accent2{background-color: $cl_accent2;}
.bg_accent2_d{background-color: $cl_accent2_d;}
.bg_accent2_d2{background-color: $cl_accent2_d2;}

.bg_accent3{background-color: $cl_accent3;}
.bg_accent3_d{background-color: $cl_accent3_d;}
.bg_accent3_d2{background-color: $cl_accent3_d2;}

.bg_accent2_01{background-color: transparentize($cl_accent2, 0.9);}
.bg_red{background-color:$cl_red !important}
.bg_green{background-color:$cl_green !important}
.bg_green_l{background-color:$cl_green_l !important}
.bg_green_vl{background-color:$cl_green_vl !important}
.bg_transparent{background-color:transparent !important}
.bg_yellow_01{background-color: transparentize($cl_yellow, 0.9);}
.bg_yellow_02{background-color: transparentize($cl_yellow, 0.8);}

.hbg:hover{background-color: $cl_bg;}
.hbg_d:hover{background-color: $cl_bg_d;}
.hbg_sd:hover{background-color: $cl_bg_sd;}
.hbg_l:hover{background-color: $cl_bg_l;}

.borderb_code{border-bottom: solid 1px $cl_code;}
.borderb_separator{border-bottom: solid 1px rgba(255, 255, 255, 0.05);}