@import "../../../c_css/a_parameters.scss";

$ly1_barh: 32px;

#layout1.technology_iframe2.F2buttonUp {
  #layout1_bar #bar1_sec2 {
    transform: translate(0px, -41px) !important;
    height: 40px !important;
  }
  .codebox .codebox-content {
    height: calc(100% - 80px) !important;
  }
}

#layout1 {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #151821;

  .codeboxes {
    min-height: 100%;
    height: 100%;
  }

  #layout1_bar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: $ly1_barh;
    width: 100%;
    z-index: 100;
    background-color: rgb(28, 31, 40);
    border-top: 1px solid #151821;
    color: $cl_primary;
    z-index: 200;

    button.bt_grey {
      //background-color: rgb(28, 31, 40);
    }

    ul li {
      display: inline;
      float: left;
      margin: 0px 3px;
      line-height: $ly1_barh;
    }

    #bar1_sec1 {
      width: 30%;
      padding: 0px 10px;
    }
    #bar1_sec2 {
      width: 35%;
      height: 100%;
      overflow: hidden;
      background-color: rgb(40, 45, 57);
      li {
        height: 100%;
        width: 25%;
        margin: 0px;
        min-width: 100px;
      }
      button {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        float: left;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 11px;
      }
    }
    #bar1_sec3 {
      padding: 0px 10px;
    }
  }

  #layout1_main {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .layout1_text {
    font-size: 15px;
    line-height: 1.5em;
    width: 30%;
    float: left;
    height: 100%;
    overflow-y: auto;
    background-color: white;
    overflow-x: hidden;
    overflow-wrap: anywhere;
    .codebox {
      border: none;
    }
    .codebox-header {
      color: #1f24306e !important;
      background-color: rgba(0, 0, 0, 0.05);
      height: 40px;
      line-height: 40px;
    }
  }
  .layout1_code {
    position: relative;
    float: left;
    width: 70%;
    height: 100%;
  }
}

#layout1.withBar {
  #layout1_main {
    padding-bottom: $ly1_barh;
  }
}

#layout1.noTech {
  .layout1_code {
    display: none;
  }
  .layout1_text {
    width: 100%;
  }
}
#layout1.noText {
  .layout1_code {
    width: 100%;
  }
  .layout1_text {
    display: none;
  }
}
