
.content_light{
  background-color:white;
}

.content_light,.content_trans{ 
  
  .content_rep{
    .content_item.itemtype_text {
      color:black;
      //h1,h2,h3,h4 { color:$cl_accent_d; }

      pre code{ 
        /*background: #313744; */
        background: #29303f;
      }
      code:not(.hljs) { 
        background: #f0f0f0;   
      }

      table{
        //td,th{border-color:$cl_code_l;}
        th{background-color: #f0f0f0;}
      }
    }

    .content_item.itemtype_dom{
      color: white;
      background-color: #29303f;
      td,th{border-color: rgb(78,83,94);}
      tr.color1{td,th{color:rgb(224, 108, 117);}}
      tr.color2{td,th{color:#d19a66;}}
      button{background-color:#cccccc ; color:black ; border: solid 1px #cccccc; cursor:pointer}
      button:hover{background-color:#dddddd; }
    }
  }
}
