@import "../../c_css/a_parameters.scss";

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  cursor:pointer;
}

.modal-box {
  z-index: 2;
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  color: black;
}

.modal-head {
  padding: 10px 10px;
  font-size: 20px;
  line-height: 30px;
  background-color: rgba(0,0,0,0.5);
  color: white;
}

.modal-body {
  padding: 10px;
  min-height: 100px;
  font-size: 16px;
}
