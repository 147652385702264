@import "../../c_css/a_parameters.scss";

.__console_row {
  margin-bottom: 0px;
  width: 100%;
  float: left;
}
.__console_row .__linenumber {
  color: rgba(61, 66, 77, 99);
  width: 21px;
  float: left;
  text-align: right;
  padding-right: 5px;
  padding-left: 3px;
}
.__console_row .__cursor {
  float: left;
  width: calc(100% - 30px);
  padding: 0px 4px;
  padding-left: 15px;
  text-indent: -15px;
}
.__console_row .__cursor.indent15 {
  text-indent: 0px;
}

#__test,
#__console {
  font-family: monospace;
  background-color: #1f2430;
  color: #e5e5e5;
  padding: 4px 0px;
}

.__row {
  position: relative;
  float: left;
  width: 100%;
}
.__p5 {
  padding: 5px;
}
.__p0_5 {
  padding: 0px 5px;
}
.__m0_5 {
  margin: 0px 5px;
}

.__cl_warning {
  color: #ffcc66;
}
.__cl_red {
  color: #f44336;
}
.__cl_green {
  color: #4caf50;
}
.__cl_accent2 {
  color: #ffcc66;
}

.__hiddenscroll {
  height: calc(100% - 30px);
  overflow-y: scroll;
  width: calc(100% + 30px);
}
//--------------------

.codebox.ct_white {
  .codebox-content {
    background-color: white;
    color: black;
  }
}

#editor_loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
