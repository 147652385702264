$h_header: 50px;

.App-header {
  z-index: 10;
  position: relative;
  float: left;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;

  .header_in {
    background-color: $cl_bg;
  }
  .placeholder {
    width: 100%;
    height: 50px;
  }

  ul {
    li {
      padding: 0px 10px;
      height: $h_header;
      line-height: $h_header;
      float: left;
      cursor: pointer;
      min-width: 50px;
      text-align: center;
    }
    li.active,
    li:hover:not(.unused):not(.nohover) {
      opacity: 1;
      span {
        color: $cl_accent;
      }
    }
    li.active {
      span {
        color: $cl_accent;
      }
    }
    li.unused {
      opacity: 0.5;
      cursor: auto;
    }
  }
}
.App-header.level2 {
  background-color: $cl_code_l;
  ul {
    //margin:0px auto;
    margin-left: 70px;
    width: max-content;
  }
}

.App-header3 {
  a {
    font-size: 18px;
    opacity: 0.7;
    color: black;
    float: left;
    padding: 16px 5px;
    margin: 0px 20px 0px 0px;
  }
  .mcenter a {
    padding: 16px 5px;
    margin: 0px 10px;
  }
  a:hover,
  a.active {
    color: $cl_accent;
    opacity: 1;
  }
}

.App-logo2 {
  height: 100%;
}

.App-content {
  position: relative;
  float: left;
  width: 100%;
  min-height: calc(100vh - 50px);
}

.App-footer {
  z-index: 1;
  position: relative;
  width: 100%;
  float: left;
  background-color: $cl_code;
  color: white;
  .footerrow {
    border-top: solid 1px rgba(255, 255, 255, 0.05);
    padding: 10px;
  }
}

.App-warning {
  color: rgba(255, 255, 255, 0.8);
  background-color: $cl_red;
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

.App-content.theme-light {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
}

.fullheight_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
  width: 100%;
}

.fullheight {
  min-height: calc(100vh - 50px);
}

.page_light {
  background-color: rgba(21, 24, 33, 0.1);
  color: black;
  a {
    color: black;
  }
}

.page_title {
  line-height: 50px;
  font-size: 35px;
  margin: 0px 20px;
  font-weight: bold;
}

@media screen and (max-width: $mobile_width + px) {
  .App-content.menu_open {
    display: none;
  }
  .App-footer.menu_open {
    display: none;
  }
}
