
body,input,textarea,button,select{
    font-size:13px;
    line-height:1.3em;
    font-weight:400;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -o-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  body{
    float:left;
    min-width: 100%;
  }


  content,.content{margin:0px auto; max-width: 1024px;width: 100%; padding:0px 10px; position: relative; display: table; }
  button{background-color:transparent;border:none;}

  p{margin:10px 0px;}


  h1,h2,h3,h4,h5,h6{line-height: 1.5em;}
  h1{font-size: 36px;}
  h2{font-size: 28px;}
  h3{font-size: 24px;}
  h4{font-size: 20px;}
  h5{font-size: 18px;}
  h6{font-size: 16px;}