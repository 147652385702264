@import "../../c_css/a_parameters.scss";

.course_head {
  width: 100%;
  height: 40vh;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    min-height: 100%;
  }
  .course_title_container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
  .course_title {
    font-size: 25px;
    line-height: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 20px;
    border-radius: 5px;
    width: fit-content;
  }
}

.course_section {
  margin: 20px 0px 0px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px transparentize($cl_code, 0.8);
  min-height: 150px;

  font-size: 16px;
  line-height: 20px;
  .content_item.itemtype_text {
    font-size: 16px;
    line-height: 20px;
  }

  .course_section_header {
    font-size: 25px;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
  }

  .course_rate_container{
    padding:0px 20px;
  }
  .course_rate {
    width: 100%;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    .c_rate_bar {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $cl_green;
    }
    .c_rate_val {
      position: absolute;
      left: 0px;
      top: 0px;
      line-height: 20px;
      font-size: 16px;
      color: white;
      height: 15px;
      text-align: center;
      width: 100%;
    }
  }

  .course_attributes_container {
    padding:0px 20px;
    table {
      tr {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .course_element {
    padding: 10px 10px;
    

    .element_icon,
    .element_play_icon {
      font-size: 15px;
      line-height: 20px;
      width: 40px;
      text-align: center;
    }
    .element_play_icon {
      float: right;
    }

    .element_icon {
    }
    .element_name {
    }
  }

  .chapter_data {
    //background-color: rgba(0, 0, 0, 0.05);
    color: white;
    margin-top: 20px;
    .element_icon {
      opacity: 0.5;
    }
    .element_name {
      font-weight: bold;
    }
  }

  .chapter_item {
    padding-left: 30px;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    .element_icon {
    }
    .element_name {
    }
  }
  .chapter_item:hover {
    background-color: rgba(0, 0, 0, 0.05);
    background-color: transparentize($cl_yellow, 0.9);
  }

  .chapter_item.done {
    //background-color: transparentize($cl_green,0.95);
  }
  .chapter_item.done:hover {
    background-color: transparentize($cl_green, 0.9);
  }
}
