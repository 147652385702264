

.inputItem{
  margin:3px 0px;

  label{min-width:200px;float:left;}
  .row.error{color:$cl_red;line-height: 1.5em;padding-left:200px;}

  select:focus{outline: none;}
  input[type='text']{float:left;border-radius: 5px;min-width:500px;}
  label,input[type='text']{ padding:0px 10px;line-height: 25px; height:25px; border:none;}

  select.simpleselect{min-width:500px;float:left;border-radius: 5px;padding:0px 10px;line-height: 25px; height:25px; border:none;}
  select.multiselect{padding:5px 10px; width:200px;border:none;border-radius: 5px;}
  
  input[type='text'],select.simpleselect{background-color: $cl_bg_l;color:white;}
  input:disabled[type='text'],select.simpleselect:disabled{color:transparentize(white, 0.5);}

  .multiselect_in{
    .multi_col1,.multi_col2,.multi_col3{float:left;min-width: 100px;min-height: 1px;}
    option{cursor:pointer}
  }
  
  .multi_from.multiselect{background-color: transparentize($cl_red, 0.9);color:white}
  .multi_to.multiselect{background-color: transparentize($cl_green, 0.9);color:white}

  .switch{      
    line-height: 25px;font-size:23px;
    .fas{cursor:pointer;}}
}


.inputItem.medium{
  label{min-width:150px;}
  .row.error{padding-left:150px;}
  input[type='text'],select.simpleselect{min-width:300px;width: 300px;}
}

.inputItem.small{
  label{min-width:100px;}
  .row.error{padding-left:100px;}
  input[type='text'],select.simpleselect{min-width:200px;width: 200px;}
}

.inputItem.xsmall{
  input[type='text'],select.simpleselect{min-width:150px;width: 150px;}
}
.inputItem.xxsmall{
  input[type='text'],select.simpleselect{min-width:90px;width: 90px;}
}
.inputItem.nolabel{
  label{display:none;}
  .row.error{padding-left:0px;}
}
.inputItem.noerror{
  .row.error{display:none;}
}


@media screen and (max-width: 800px) {
  .inputItem{
    padding:0px 5px;
    label{width:100% !important; min-width: unset !important;}
    .row.error{width:100% !important; min-width: unset !important;padding-left:0px !important;}
    input[type='text'],select.simpleselect{width:100% !important; min-width: unset !important;}
  }
}




.quiz_plus{
  background-color: transparentize($cl_green, 0.8);
  color:white; 
  text-align: center;
  width: 500px;
  line-height: 25px;
  border-radius: 5px;
  margin: 0px 0px;
  margin-left: 25px;
  cursor:pointer;
}
.quiz_plus:hover{
  background-color: transparentize($cl_green, 0.7);
}

.quiz_del,.quiz_ok{
  position: relative;
  display: block;
  float: left;
  line-height: 25px;
  margin: 0px;
  width: 25px;
  text-align: center;
  cursor:pointer;
}
