// ------------------ ANIMATION ------------------------------

.lan{}
.lanindoc .lan  ,.language_in .lan  {@include transition(2.0s);transform:scale(1.0) rotate(0deg) translate(0px,0px);opacity:1;}
.lanoutdoc .lan ,.language_out .lan {@include transition(0.8s);transform:scale(1.0) rotate(0deg) translate(0px,0px) !important;opacity:0 !important;}


.rotate3s{    animation: rotateIt 3s linear infinite;	}
.hover_aC:hover{  .rotate3s_hP{    animation: rotateIt 3s linear infinite;	}}

.toload{@include transition(1.0s);transform:translate(0px,0px);opacity:1.0}
.toload.unloaded{@include transition(0s);transform:translate(0px,-50px);opacity:0.0}
.toload.unloaded.fromdown{transform:translate(0px,50px);opacity:0;}

.toload2{    animation: fadein-down-big 1.3s;	 }
.toloadup{    animation: fadein-up 1.3s;	 }
.toloadr{    animation: fadein-right 1.3s;	 }
.toloadrb{    animation: fadein-right-big 1.3s;	 }
.toloads{    animation: fadein-op 1.3s; display: inline-block;	 }
.scalein{    animation: scale-in-1 0.8s;       }


    @keyframes rotateIt {
        0% {    transform: rotate(0deg);   }
        100% {  transform: rotate(360deg); }
    }
    @-webkit-keyframes rotateIt {
        0% {   transform: rotate(0deg);   }
        100% { transform: rotate(360deg); }
    }

    @keyframes hideit {
        0% {    transform: rotate(0deg) scale(1.0); opacity: 1; }
        2% {    transform: rotate(0deg) scale(1.3); opacity: 1; }
        100% {  transform: rotate(0deg) scale(0.2); opacity: 0; }
    }
    @-webkit-keyframes hideit {
        0% {    transform: rotate(0deg) scale(1.0); opacity: 1; }
        2% {    transform: rotate(0deg) scale(1.3); opacity: 1; }        
        100% {  transform: rotate(0deg) scale(0.2); opacity: 0; }
    }

    @keyframes fadein-down-big {
        0% {    transform: translate(0px,-50px);opacity:0.0   }
        25% {   transform: translate(0px,-50px);opacity:0.0   }
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }
    @-webkit-keyframes fadein-down-big {
        0% {    transform: translate(0px,-50px);opacity:0.0   }
        25% {   transform: translate(0px,-50px);opacity:0.0   }        
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }
    @keyframes fadein-up {
        0% {    transform: translate(0px,10px);opacity:0.0   }
        25% {   transform: translate(0px,10px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }
    @-webkit-keyframes fadein-up {
        0% {    transform: translate(0px,10px);opacity:0.0   }
        25% {   transform: translate(0px,10px);opacity:0.0   }        
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }
   
    @keyframes fadein-right {
        0% {    transform: translate(-10px,0px);opacity:0.0   }
        25% {   transform: translate(-10px,0px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }



    @keyframes fadein-right-big {
        0% {    transform: translate(-50px,0px);opacity:0.0   }
        25% {   transform: translate(-50px,0px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }
    @-webkit-keyframes fadein-right {
        0% {    transform: translate(-10px,0px);opacity:0.0   }
        25% {   transform: translate(-10px,0px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }
    @-webkit-keyframes fadein-right-big {
        0% {    transform: translate(-50px,0px);opacity:0.0   }
        25% {   transform: translate(-50px,0px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }



    @keyframes fadein-op {
        0% {    transform: translate(0px,0px);opacity:0.0   }
        25% {   transform: translate(0px,0px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }

    @keyframes scale-in-1 {
        0% {    transform: scale(0.7);opacity:0.0   }
        100% {    transform: scale(1);opacity:1.0   }
    }
    @-webkit-keyframes scale-in-1 {
        0% {    transform: scale(0.7);opacity:0.0   }
        100% {    transform: scale(1);opacity:1.0   }
    }


    .toload3 { 
        opacity: 0.0;
        animation-name:fadein-right2;
        animation-fill-mode: forwards;
        animation-duration: 2s;
    }


    .toload3.s1 { animation-delay: 1.0s; }
    .toload3.s2 { animation-delay: 2.0s; }
    .toload3.s3 { animation-delay: 3.0s; }
    .toload3.s4 { animation-delay: 4.0s; }
    .toload3.s5 { animation-delay: 5.0s; }
    .toload3.s6 { animation-delay: 6.0s; }
    .toload3.s7 { animation-delay: 7.0s; }
    .toload3.s8 { animation-delay: 8.0s; }
    

    @keyframes fadein-right2 {
        0% {    transform: translate(-50px,0px);opacity:0.0   }
        1% {   transform: translate(-50px,0px);opacity:0.0   }    
        100% {  transform: translate(0px,0px);opacity:1.0 }
    }



    .blink {
        animation: blink-animation;
        -webkit-animation: blink-animation;
        animation-duration: 1s;
        animation-timing-function: steps(2, start);
        animation-iteration-count: 8;
      }
      @keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
      @-webkit-keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }