@import "../../c_css/a_parameters.scss";

.site_course_group {
  padding: 10px 0px;
  //margin: 20px 0px;
  .image {
    width: 50px;
    height: 50px;
    float: left;
    margin: 0px 10px;
  }
  .icon {
    font-size: 40px;
    line-height: 50px;
    float: left;
    margin: 0px 10px;
  }
  .ptitle {
    line-height: 60px;
    font-size: 42px;
    margin: 0px 20px;
    font-weight: bold;
    display: inline;
    padding: 0px 10px;  
    border-bottom: solid 5px $cl_accent;
  }
  .title {
    line-height: 50px;
    font-size: 35px;
    margin: 0px 20px;
    font-weight: bold;
    /*border-bottom: solid 5px;*/
    border-left: solid 20px;
    /*border-color: #2baad9;*/
    border-color: rgba(0,0,0,0.3);
    padding: 0px 10px;  
    text-align: left;
    .fas,.far{display:none;}
  }
  .desc {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 22px;
  }


}





.site_course_overview {
  position: relative;
  float: left;
  width: 250px;
  margin-bottom:30px;
  padding: 8px;
  padding: 10px;

  .c_mode_msg{
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    font-size:15px;
    padding:10px;
    color:$cl_red;
    text-align: center;
    //display: none;
    .c_mode_msg_icon{margin-right:5px; font-size:13px;display:none;}
    z-index: 3;
    background-color:#eab13b;
    transform: rotate(45deg);
    color: white !important;
    top: 20px;
    left: 80px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  }

  .c_in {
    border-radius: 5px;
    overflow: hidden;    
  }

  .c_image {
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .c_body {
    padding: 8px;
    padding-bottom: 30px;
    background-color: white;
    color: black;
    height: 160px;
    overflow: hidden;
  }
  .c_name {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.3em;
    border-radius: 5px;
  }
  .c_desc {
    margin: 10px 0px;
    font-size: 15px;
    line-height: 1.3em;
  }
  .c_rate {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    background-color: rgba(0,0,0,0.6);
    overflow: hidden;
    .c_rate_bar {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $cl_green;
    }
    .c_rate_val {
      position: absolute;
      left: 0px;
      top: 0px;
      line-height: 20px;
      font-size: 16px;
      color: white;
      height: 15px;
      text-align: center;
      width:100%;
    }
  }

  .c_next {
    width: 100%;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 40px;
    background-color: $cl_accent2;
  }
  .c_next:hover {
    background-color: $cl_accent2_d;
  }



}

.site_course_overview.demo1{
  width: 250px;
  .c_body{position:absolute; top:0px; left:0px; width:100%; height:100%; background-color: transparent;}
  .c_name{background-color: rgba(0,0,0,0.5); color:white; padding:5px; letter-spacing: 1px; float: left;}
  .c_image{height:150px}
}


.site_course_overview.mode_available .c_in{
  cursor: pointer;
  transition: 0.3s;
  .c_image img {      transition: 0.5s;    }
}

.site_course_overview .c_in{
  box-shadow: 0px 0px 10px transparentize($cl_code, 0.8);  
}


.site_course_overview.mode_available:hover,.site_course_overview.mode_demo1:hover{
  .c_in{    
    box-shadow: 0px 0px 20px transparentize($cl_code, 0.6);  
  }
} 

.site_course_overview.mode_developing, .site_course_overview.mode_planned, .site_course_overview.mode_locked{
  
  .c_mode_msg{
    display: block;

  }
  .c_in{
    //opacity: 0.8;
    //opacity: 1.0;
    //transform: scale(0.9) translate(0px, 8px);
  }
  .vote:hover{
    background-color: rgba(0,0,0,0.1);
  }
}


@media screen and (max-width: $mobile_width + px) {
  .site_course_group {
    .ptitle{    
      line-height: 50px;
      font-size: 35px;
      margin:0px;
    }
    .title{
      line-height: 40px;
      font-size: 25px;
      margin: 0px 5px;
    }
    .desc{
      padding: 10px 5px;
    }
  }

}
@media screen and (max-width: 500px) {}