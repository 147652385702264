//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.font-mon{font-family: 'Montserrat', sans-serif;}

//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.font-sans{font-family: 'Open Sans', sans-serif;}

//@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
.font-yu{font-family: 'Yusei Magic', sans-serif;}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.font-rob{font-family: 'Roboto', sans-serif;}

//@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.font-karla{font-family: 'Karla', sans-serif; }


//@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.font-ral{font-family: 'Raleway', sans-serif; }

//@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.font-nun{font-family: 'Nunito', sans-serif; }

body,input,textarea,button,select{
    font-family: 'Roboto', sans-serif;
}

