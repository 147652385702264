#technology_iframe2 {


  .iframe2_col_out.info_console1,
  .iframe2_col_out.info_test1 {
    #iframe2_iframe {
      height: 65%;
    }
    #iframe2_info {
      height: 35%;
    }
    .iframe2_info_body {
      opacity: 1;
      min-height: 100px;
    }
  }



  .iframe2_col_out.info_console2,
  .iframe2_col_out.info_test2 {
    #iframe2_iframe {
      height: 65%;
    }
    #iframe2_info {
      height: 100%;
      border-top: none;
    }
    .iframe2_info_body {
      opacity: 1;
      min-height: 100px;
    }
  }


  .iframe2_col_out.info_split{
    #iframe2_info {
      height: 100%;
      border-top: none;
    }

    .iframe2_info_body {
      opacity: 1;
      min-height: 100px;
    }
  }  


}