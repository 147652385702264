.mw100{width:100%;max-width:100px;margin:0px auto;}
.w100m200,.mw200{width:100%;max-width:200px;margin:0px auto;}
.w100m250,.mw250{width:100%;max-width:250px;margin:0px auto;}
.w100m300,.mw300{width:100%;max-width:300px;margin:0px auto;}
.w100m400,.mw400{width:100%;max-width:400px;margin:0px auto;}
.w100m450,.mw450{width:100%;max-width:450px;margin:0px auto;}
.w100m500,.mw500{width:100%;max-width:500px;margin:0px auto;}
.w100m600,.mw600{width:100%;max-width:600px;margin:0px auto;}
.w100m700,.mw700{width:100%;max-width:700px;margin:0px auto;}
.w100m750,.mw750{width:100%;max-width:750;margin:0px auto;}
.w100m800,.mw800{width:100%;max-width:800px;margin:0px auto;}
.w100m900,.mw900{width:100%;max-width:900px;margin:0px auto;}
.w100m1000,.mw1000{width:100%;max-width:1000px;margin:0px auto;}

.mw100p{max-width: 100%;}

.mh100{min-height:100px;} .mh200{min-height:200px;} .mh300{min-height:300px;} .mh400{min-height:400px;} .mh500{min-height:500px;} 
.mh600{min-height:600px;} .mh700{min-height:700px;} .mh800{min-height:800px;} .mh900{min-height:900px;} .mh1000{min-height:1000px;} 
.maw100{max-width:100px;} .maw200{max-width:200px;} .maw300{max-width:300px;} .maw400{max-width:400px;} .maw500{max-width:500px;} 
.maw600{max-width:600px;} .maw700{max-width:700px;}
.miw20{min-width:20px}
.miw25{min-width:25px}
.miw30{min-width:30px}
.miw100{min-width:100px}
.miw150{min-width:150px}
.miw200{min-width:200px !important}
.miw300{min-width:300px !important}
.miw400{min-width:400px !important}
.miw500{min-width:500px !important}
.miw600{min-width:600px !important}
.miw1000{min-width:1000px !important}

.col2,.col2_1{width:50%;float:left;min-height:1px;}
.col3,.col3_1{width:33.3333%;float:left;min-height:1px;}	.col3_2{width:66.6666%;float:left;min-height:1px;}
.col4,.col4_1{width:25%;float:left;min-height:1px;}		.col4_3{width:75%;float:left;min-height:1px;}
.col5,.col5_1{width:20%;float:left;min-height:1px;}	.col5_2{width:40%;float:left;min-height:1px;}	.col5_3{width:60%;float:left;min-height:1px;}	.col5_4{width:80%;float:left;min-height:1px;}
.col6,.col6_1{width:16.5%;float:left;min-height:1px;}
.col12{width:8.25%;float:left;min-height:1px;}

.w25{width: 25%;}    .w33{width: 33.333333%;}
.w40{width: 40%;} .w50{width: 50%;} .w100{width:100%} .w48{width:48% !important}
.w40p{width: 40px;} .w50p{width:50px;}

.p0{padding:0px !important;} .p5{padding:5px !important;} .p15{padding:15px !important;} .p10{padding:10px !important;} .p20{padding:20px !important;} 
.p30{padding:30px !important;} .p50{padding:50px !important;}
.p_lr5{padding-left:5px;padding-right:5px;} .p_lr10{padding-left:10px;padding-right:10px;}
.p2{padding:2px;} .p3{padding:3px;} .p4{padding:4px;} .p6{padding:6px !important;} .p7{padding:7px !important;}
.p0_4i{padding:0px 4px !important}
.p0_5{padding:0px 5px;}
.p2_0{padding:2px 0px;} .p2_5{padding:2px 5px;}  .p2_10{padding:2px 10px;}
.p3_0{padding:3px 0px;} .p3_5{padding:3px 5px;}  .p3_10{padding:3px 10px;}
.p10_5{padding:10px 5px;} .p15_5{padding:15px 5px;} .p20_5{padding:20px 5px  !important;}
.p5_0{padding:5px 0px !important;} .p10_0{padding:10px 0px;} .p15_0{padding:15px 0px;}  
.p20_0{padding:20px 0px  !important;} .p30_0{padding:30px 0px  !important;} .p40_0{padding:40px 0px  !important;} .p50_0{padding:50px 0px  !important;}
.p0_10{padding:0px 10px !important;} .p0_20{padding:0px 20px !important;} .p0_30{padding:0px 30px !important;} 
.p5_10{padding:5px 10px !important;} .p5_20{padding:5px 20px !important;} .p5_30{padding:5px 30px !important;} 
.p0_50{padding:0px 50px !important;}	.p0_60{padding:0px 60px !important;} .p0_70{padding:0px 70px !important;} .p0_80{padding:0px 80px !important;}		
.p10_20{padding:10px 20px;}
.p15_10{padding:15px 10px !important;}	.p20_10{padding:20px 10px !important;} 	.p20_40{padding:20px 40px !important;} 	.p20_50{padding:20px 50px !important;} 
.p25_10{padding:25px 10px !important;} 
.p30_10{padding:30px 10px !important;} .p40_10{padding:40px 10px !important;} .p50_10{padding:50px 10px !important;}

.pr5{padding-right:5px} .pr10{padding-right:10px} .pr20{padding-right:20px} .pr30{padding-right:30px} .pr40{padding-right:40px}	.pr50{padding-right:50px} .pr100{padding-right:100px}
.pb10{padding-bottom:10px;} .pb20{padding-bottom:20px;} .pb50{padding-bottom:50px;} .pb100{padding-bottom:100px;} .pbottom100{padding-bottom:100px;}	
.ph5{padding-top:5px;padding-bottom:5px;} .ph10{padding-top:10px;padding-bottom:10px;} .ph15{padding-top:15px;padding-bottom:15px;} 
.ph20{padding-top:20px;padding-bottom:20px;} .ph30{padding-top:30px;padding-bottom:30px;} .ph40{padding-top:40px;padding-bottom:40px;}
.ph50{padding-top:50px;padding-bottom:50px;}

.pl5{padding-left:5px} .pl10{padding-left:10px} .pl20{padding-left:20px} .pl30{padding-left:30px} .pl40{padding-left:40px} .pl50{padding-left:50px}
.pl4{padding-left:4px}
.ptop1{padding-top:1px;}
.ptop10{padding-top:10px !important;}.ptop20{padding-top:20px !important;}
.pt10{padding-top:10px !important;}.pt20{padding-top:20px !important;}.pt30{padding-top:30px !important;}.pt40{padding-top:40px !important;}.pt50{padding-top:50px !important;}
.pt60{padding-top:60px !important;}.pt70{padding-top:70px !important;}.pt80{padding-top:80px !important;}.pt90{padding-top:90px !important;}.pt100{padding-top:100px !important;}	

.m0{margin:0px !important;} .m5{margin:5px !important;} .m10{margin:10px !important;} .m20{margin:20px !important;} .m30{margin:30px !important;} .m50{margin:50px !important;}
.m3{margin:3px !important;}
.mtop3{margin-top:3px !important;}
.mtop5{margin-top:5px !important;} .mtop10{margin-top:10px !important;}	.mtop15{margin-top:15px !important;}	
.mtop20{margin-top:20px !important;}	.mtop30{margin-top:30px !important;}	.mtop40{margin-top:40px !important;}	.mtop50{margin-top:50px !important;}
.mtop100{margin-top:100px;}
.mleft5{margin-left:5px} .mleft10{margin-left:10px} .mleft20{margin-left:20px} .mleft30{margin-left:30px}
.mright5{margin-right:5px} .mright10{margin-right:10px} .mright15{margin-right:15px} .mright20{margin-right:20px} .mright30{margin-right:30px}
.mbottom0{margin-bottom:0px;}  .mbottom10{margin-bottom:10px;} .mbottom20{margin-bottom:20px;} .mbottom30{margin-bottom:30px;} .mbottom40{margin-bottom:40px;} 
.m5_0{margin:5px 0px;} 
.m10_0{margin:10px 0px;} .m20_0{margin:20px 0px;} .m30_0{margin:30px 0px;} .m40_0{margin:40px 0px;} .m50_0{margin:50px 0px;}
.m100_0{margin:100px 0px;} .m150_0{margin:150px 0px;}
.mright8{margin-right:8px} .mleft8{margin-left:8px}
.mbottom100{margin-bottom:100px;}
.mbottom_m10{margin-bottom:-10px;}
.mbottom_m15{margin-bottom:-15px;}
.mtb10{margin-top:10px;margin-bottom:10px;}
.m_lr10{margin-left:10px !important;margin-right:10px !important;}
.m_lr20{margin-left:20px !important;margin-right:20px !important;}

.m0auto{margin:0px auto;}
.m0a{margin:0px auto !important;} .m5a{margin:5px auto;} .m10a{margin:10px auto;} .m20a{margin:20px auto;}
.mcenter{margin-left:auto !important; margin-right:auto !important; display: block; width:max-content;max-width: 100%;}

.boxshadow_20_05{box-shadow: 0px 0px 20px rgba(0,0,0,0.5)}
.boxshadow_10_02{box-shadow: 0px 0px 10px rgba(0,0,0,0.2)}
.boxshadow_5_02{box-shadow: 0px 0px 10px rgba(0,0,0,0.2)}
.op9{opacity:0.9} .op8{opacity:0.8} .op7{opacity:0.7} .op6{opacity:0.6} .op5{opacity:0.5} 
.op4{opacity:0.4} .op3{opacity:0.3} .op2{opacity:0.2}  .op1{opacity:0.1} .op0{opacity:0.0 !important}
.op80{opacity:0.8} .op70{opacity:0.7} .op60{opacity:0.6} .op50{opacity:0.5} .op40{opacity:0.4} 
.op85{opacity:0.85} .op75{opacity:0.75} .op65{opacity:0.65} .op55{opacity:0.55} .op45{opacity:0.45} 

.nodisplay{display:none;}

.table2,.display-table{ margin: 0 auto;float: none; display: table;}
.row{width:100%;float:left;position:relative;}
.wh100{width:100%;height:100%;}
.w50{width: 50%;}
.h100{height: 100%;}
.halfrow{width:50%;float:left;position:relative;}
.row2{width:100%;float:left;display:block;position: relative;}

.left{float:left !important}
.eleft{float:left}
.right{float:right !important;}
.eright{float:right;}

.text-left{text-align:left !important;}
.text-right{text-align:right !important;}
.text-center{text-align:center !important;}
.text-justify{text-align:justify !important;}
.img-center img{margin-left: auto; margin-right: auto;}
.text-notransform{text-transform:none !important;}
.text-lowercase{text-transform: lowercase;}
.text-uppercase{text-transform: uppercase;}
.text-capital{text-transform: capitalize;}
.text-underline{text-decoration: underline;}
.text-ls1{letter-spacing: 1px;}
.text-ls2{letter-spacing: 2px;}
.text-ls3{letter-spacing: 3px;}
.text-mw100{max-width: 100px;}
.text-mw120{max-width: 120px;}
.text-mw150{max-width: 150px;}
.text-mw200{max-width: 200px;}
.text-ellipsis{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.text-cross{text-decoration: line-through;}
.underline{text-decoration: underline;}

.borderr5{border-radius: 5px;}

.pointer{cursor:pointer;}
.absolute{position:absolute !important;}
.absolute.tl0{top:0px;left:0px;}
.absolute.bl0{bottom:0px;left:0px;}
.absolute.tr0{top:0px;right:0px;}
.relative{position:relative;}
.fixed{position:fixed;}
.nooverflow{overflow:hidden;}
.inline{display: inline-block;}
.block{display: block;}
.z-1{z-index: -1;}
.z_1{z-index: 1;}
.z_10{z-index: 10;}
.z_100{z-index: 100;}

.flexcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}


.flex{position:relative;margin:0 auto;	display:table; }

.noselect {      
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.yesselect {      
    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.italic{font-style:italic;}
.justify{text-align:justify}
.bold{font-weight:bold;}
.nbold{font-weight: normal !important;}

span.list{padding-right:5px;}
b{font-weight:bold;}
/*i{font-style:italic;}*/


img.fullcenter{
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
}



img.fullcenter.maxhw100,img.maxhw100{
    max-width: 100%;
}

.hoverop:hover{opacity:1.0}
.hoverop1:hover{opacity:1.0}
.hoverop100:hover{opacity:1.0}
.hoverop5:hover{opacity:0.5}
.hoverop50:hover{opacity:0.5}
.hoverop7:hover{opacity:0.7}
.hoverop8:hover{opacity:0.8}


.hoverop_par .hoverop_ch{opacity: 0;}
.hoverop_par:hover .hoverop_ch{opacity:1 !important}