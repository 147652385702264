.col5_m4{width:20%;float:left;}
.col5_m3{width:20%;float:left;}
.col5_m2{width:20%;float:left;}
.col5_m1{width:20%;float:left;}
.col4_m3{width:25%;float:left;}
.col4_m2{width:25%;float:left;}
.col4_m1{width:25%;float:left;}
.col3_2_m1{width:66.6666%;float:left;min-height:1px;}
.col3_1_m1{width:33.3333%;float:left;min-height:1px;}
.col2_m1{width:50%;float:left;min-height:1px;}

@media screen and (min-width: ($mobile_width + 1) + px) {
    .onmobile{display: none !important;}


}

@media screen and (max-width: $mobile_width + px) {
    /* -------------- general --------------- */
    .nomobile{display:none;}
    .col5_m4{width:25%}
    .col5_m3{width:33.33333%}
    .col5_m2{width:50%}
    .col5_m1{width:100%}
    .col4_m3{width:33.33333%}
    .col4_m2{width:50%}
    .col4_m1{width:100%}
    .col3_2_m1{width:100%}
    .col3_1_m1{width:100%}
    .col2_m1{width:100%}



    .App-header .header_in{
        position: fixed;
        top:0px; left:0px;
        ul li{
            width:100%;
            text-align: left;
        };
        //transition: 0.3s;
        height: 50px;
        overflow: hidden;
        background-color: #484848;
        background-color: #353d4e;
        .header_level2{
            background-color: rgba(0,0,0,0.07);
            float: left; width: 100%;
            li{
                text-indent: 30px;
            }
        }
    }

    .App-header .header_in.open{
        height: 100%;
        overflow-y: auto;
    }
    .landing_main_image{
        max-width: 200% !important;
        margin-left: -200px;
    }



}



.mw1000_500_250{width: 100%; max-width: 1000px;margin: 0px auto;}
.mw900_600_300{width: 100%; max-width: 900px;margin: 0px auto;}
.mw800_400_200{width: 100%; max-width: 800px;margin: 0px auto;}
.mw800_400{width: 100%; max-width: 800px;margin: 0px auto;}
.mw700_350{width: 100%; max-width: 700px;margin: 0px auto;}
.mw600_300{width: 100%; max-width: 600px;margin: 0px auto;}
.mw500_250{width: 100%; max-width: 500px;margin: 0px auto;}
.mw450_300{width: 100%; max-width: 450px;margin: 0px auto;}

@media screen and (max-width: 1020px) {
    .mw1000_500_250{max-width: 500px;}
}
@media screen and (max-width: 920px) {
    .mw900_600_300{max-width: 600px;}
}
@media screen and (max-width: 820px) {
    .mw800_400_200{max-width: 400px;}
    .mw800_400{max-width: 400px;}
}
@media screen and (max-width: 720px) {
    .mw700_350{max-width: 350px;}
}
@media screen and (max-width: 620px) {
    .mw900_600_300{max-width: 300px;}
    .mw600_300{max-width: 300px;}
}
@media screen and (max-width: 520px) {
    .mw1000_500_250{max-width: 250px;}
    .mw500_250{max-width: 250px;}
}
@media screen and (max-width: 470px) {
    .mw450_300{max-width: 300px;}
}
@media screen and (max-width: 420px) {
    .mw800_400_200{max-width: 200px;}
}


@media screen and (max-width: 800px) {

    .col3{width: 50%;}
}

@media screen and (max-width: 600px) {

    .col2{width: 100%;}
    .col3{width: 100%;}
}

