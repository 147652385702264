@import "../../c_css/a_parameters.scss";

#landing_page {


  .pagerow {
    float: Left;
    position: relative;
    width: 100%;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagerow.fullrow {
    max-height: 700px;
    overflow: hidden;
    height: 100vh;
    width: 100%;
  }

  .pagerowbg {
  }
  .pagerowimg {
    min-width: 100%;
    max-width: 120%;
    min-height: 100%;
    /*max-height: 110%;*/
    opacity: 0.7;
  }
  .pagerowtext {
    /* position: absolute; */
    /* top: 60px; */
    /* left: 40px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    .typ1 span {
      font-size: 45px;
      line-height: 1.5em;
      background-color: transparentize($cl_code, 0.2);
      color: white;
      padding: 5px 15px;
      margin: 3px;
      border-radius: 5px;
    }
    .typ2 span {
      font-size: 25px;
      line-height: 1.7em;
      background-color: transparentize($cl_code, 0.2);
      color: white;
      padding: 5px 15px;
      margin: 3px;
      border-radius: 5px;
    }


  }

  



}
