$time_load_bg:1.5s;
$time_load_hover:0.2s;
$time_load_fast:0.5s;
$time_load_medium:1.0s;

@mixin tans_load_bg() {
  @include transition($time_load_bg);
}

@mixin tans_load_hover() {
  @include transition($time_load_hover);
}

@mixin tans_load_fast() {
  @include transition($time_load_fast);
}

@mixin tans_load_medium() {
  @include transition($time_load_medium);
}



// .........................

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}



@mixin row() {
  position: relative;
  width: 100%;
  float: left;
}

@mixin awh100() {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

@mixin fwh100() {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}



// -------------- inputs ---------------------

@mixin unselectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


// ------------ colors -----------------

@mixin gradright($grads) {
  //background: red; /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  background: -webkit-linear-gradient(left, #{$grads});
  /* For Opera 11.1 to 12.0 */
  background: -o-linear-gradient(left, #{$grads});
  /* For Fx 3.6 to 15 */
  background: -moz-linear-gradient(left, #{$grads});
  /* Standard syntax */
  background: linear-gradient(to right, #{$grads});
  background: -ms-linear-gradient(left, #{$grads});
}


@mixin gradtop($grads) {
  // gba(248,80,50,1) 0%, rgba(241,111,92,1) 36%, 
  //background: red; /* For browsers that do not support gradients */
  /* For Safari 5.1 to 6.0 */
  background: -webkit-linear-gradient(top, #{$grads});
  /* For Opera 11.1 to 12.0 */
  background: -o-linear-gradient(top, #{$grads});
  /* For Fx 3.6 to 15 */
  background: -moz-linear-gradient(top, #{$grads});
  /* Standard syntax */
  background: linear-gradient(to bottom, #{$grads});
  background: -ms-linear-gradient(top, #{$grads});
}


@mixin grad($grad1, $grad2, $grad3) {
  //background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient($grad1, $grad2, $grad3);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($grad1, $grad2, $grad3);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($grad1, $grad2, $grad3);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient($grad1, $grad2, $grad3);
  /* Standard syntax */
}


@mixin placeholder($color) {
  ::-webkit-input-placeholder {
    color: #{$color}
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #{$color}
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #{$color}
  }

  :-ms-input-placeholder {
    color: #{$color}
  }
}

// ------------ animation / transition ------------

@mixin transition($sec, $del:0s) {
  transition: $sec !important;
  -webkit-transition: $sec !important;
  -webkit-transition-delay: $del !important;
  transition-delay: $del !important;
}

@mixin animation_create_opacity($name, $form, $to) {
  @keyframes #{$name} {
    from {
      opacity: $form;
    }

    to {
      opacity: $to;
    }
  }

  @-webkit-keyframes #{$name} {
    from {
      opacity: $form;
    }

    to {
      opacity: $to;
    }
  }
}


@mixin animation_create_transform($name, $form, $to) {
  @keyframes #{$name} {
    from {
      -ms-transform: $form;
      /* IE 9 */
      -webkit-transform: $form;
      /* Safari */
      transform: $form;
    }

    to {
      -ms-transform: $to;
      /* IE 9 */
      -webkit-transform: $to;
      /* Safari */
      transform: $to;
    }
  }

  @-webkit-keyframes #{$name} {
    from {
      -ms-transform: $form;
      /* IE 9 */
      -webkit-transform: $form;
      /* Safari */
      transform: $form;
    }

    to {
      -ms-transform: $to;
      /* IE 9 */
      -webkit-transform: $to;
      /* Safari */
      transform: $to;
    }
  }
}

@mixin animation_create_bg($name, $color) {
  @keyframes #{$name} {
    0% {
      background-color: transparent
    }

    20% {
      background-color: $color;
    }

    70% {
      background-color: $color;
    }

    100% {
      background-color: transparent;
    }
  }

  @-webkit-keyframes #{$name} {
    0% {
      background-color: transparent
    }

    20% {
      background-color: $color;
    }

    70% {
      background-color: $color;
    }

    100% {
      background-color: transparent;
    }
  }
}


@mixin animation_create_actions($name, $form, $to, $op) {
  @keyframes #{$name} {
    0% {
      -ms-transform: $form;
      /* IE 9 */
      -webkit-transform: $form;
      /* Safari */
      transform: $form;
      opacity: 0.0;
      display: block;
    }

    50% {
      //-ms-transform: translate(0px,0px); /* IE 9 */
      //-webkit-transform: translate(0px,0px); /* Safari */
      //transform: translate(0px,0px);
      opacity: $op;
      display: block;
    }

    100% {
      -ms-transform: $to;
      /* IE 9 */
      -webkit-transform: $to;
      /* Safari */
      transform: $to;
      opacity: 0.0;
      display: block;
    }
  }

  @-webkit-keyframes #{$name} {
    0% {
      -ms-transform: $form;
      /* IE 9 */
      -webkit-transform: $form;
      /* Safari */
      transform: $form;
      opacity: 0.0;
      display: block;
    }

    50% {
      //-ms-transform: translate(0px,0px); /* IE 9 */
      //-webkit-transform: translate(0px,0px); /* Safari */
      //transform: translate(0px,0px);
      opacity: $op;
      display: block;
    }

    100% {
      -ms-transform: $to;
      /* IE 9 */
      -webkit-transform: $to;
      /* Safari */
      transform: $to;
      opacity: 0.0;
      display: block;
    }
  }
}


@mixin animation_load_linear_inf($name, $time) {
  animation: $time linear 0s normal infinite $name;
  -webkit-animation: $time linear 0s normal infinite $name;
  -moz-animation: $time linear 0s normal infinite $name;
  -o-animation: $time linear 0s normal infinite $name;
}


// -------  tranform

@mixin perspective($pers) {
  -webkit-perspective: $pers;
  perspective-origin: $pers;
  perspective: $pers;
}

@mixin nobackface() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin transformorigin($to) {
  transform-origin: $to;
  -webkit-transform-origin: $to;
}


// --------- css filters


@mixin greyscale($gs) {
  /*    100%    */
  -webkit-filter: grayscale($gs);
  /* Chrome, Safari, Opera */
  filter: grayscale($gs);
}

@mixin filter($filter) {
  /*   grayscale(100%);*/
  -webkit-filter: $filter;
  filter: $filter;
}


/*
@mixin flip01(){

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    transform-style: preserve-3d;
    
    transform-origin: right;

    -webkit-perspective: 1000px;
    perspective-origin: 1000px;
    perspective: 1000px;
}













*/