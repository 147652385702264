@import "../../c_css/a_parameters.scss";

#m-messages {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 400px;
  padding: 0px;
  max-width: calc(100% - 20px);
  z-index: 5;
}

.m-message {
  position: relative;
  padding: 0px 40px;
  margin-top: 10px;
  background-color: #272727;
  border-radius: 5px;
  width: 100%;
  opacity: 0;
  animation: fadein-m-message 9s;

  .m-text,
  .m-close,
  .m-icon {
    position: relative;
    font-size: 15px;
    line-height: 30px;
  }
  .m-close {
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0.5;
    cursor: pointer;
    width: 35px;
    text-align: center;
  }
  .m-close:hover {
    opacity: 1;
  }
  .m-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    text-align: center;
  }
}

.m-message.error {
  color: $cl_error;
  background-color: $bg_error;
}
.m-message.warning {
  color: $cl_warning;
  background-color:$bg_warning;
}
.m-message.info {
  color: $cl_info;
  background-color: $bg_info;
}
.m-message.success {
  color: $cl_success;
  background-color: $bg_success;
}



@keyframes fadein-m-message {
  0% {   transform: translate(-100px,0px);opacity:0.0   }
  15% {  transform: translate(0px,0px);opacity:1.0 }
  80% {  transform: translate(0px,0px);opacity:1.0 }
  100% { transform: translate(0px,0px);opacity:0.0   }
}
@-webkit-keyframes fadein-m-message {
  0% {   transform: translate(-100px,0px);opacity:0.0   }
  15% {  transform: translate(0px,0px);opacity:1.0 }
  80% {  transform: translate(0px,0px);opacity:1.0 }
  100% { transform: translate(0px,0px);opacity:0.0   }
}