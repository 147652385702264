button,
button:active,
button:focus,
button:hover {
  outline: none;
}

.button {
  @include transition(0.1s);
  padding: 0px 10px;
  line-height: 26px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  display: inline-block;
}

a.cl_accent:hover {
  color: $cl_accent_d !important;
}

.button.med {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 27px;
  line-height: 30px;
  padding: 0px 10px;
  min-width: 150px;
}

.button.big {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 35px;
  padding: 0px 10px;
  min-width: 150px;
}

.button.bt_accent {
  background-color: $cl_accent_d;
  color: white;
}
.button.bt_accent:not(:disabled):hover {
  background-color: $cl_accent_d2;
}
.button.bt_accent2 {
  background-color: $cl_accent2_d;
  color: white;
}
.button.bt_accent2:not(:disabled):hover {
  background-color: $cl_accent2_d2;
}
.button.bt_red {
  background-color: $cl_warning;
  color: white;
}
.button.bt_red:not(:disabled):hover {
  background-color: darken($cl_warning, 5%);
}
.button.bt_yellow {
  background-color: $cl_yellow;
  color: white;
}
.button.bt_yellow:not(:disabled):hover {
  background-color: darken($cl_yellow, 10%);
}

.button:disabled {
  opacity: 0.7;
}

.button.bt_grey {
  @include transition(0.1s);
  background-color: $cl_grey;
  color: white;
}
.button.bt_grey:not(:disabled):hover {
  background-color: $cl_grey_d;
}

.button.bt_trans_bo_white {
  line-height: 33px;
  @include transition(0.1s);
  background-color: transparent;
  border: solid 1px #fff;
  color: white;
}
.button.bt_trans_bo_white:hover {
  background-color: $cl_grey_d;
  background-color: transparentize(#fff, 0.7);
}

input,
input:focus {
  border: none;
  outline: none;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  line-height: 34px;
  padding: 0px 2px;
}

input.bt_trans_bo_white {
  border-bottom: solid 1px rgba(255, 255, 255, 0.8);
  background-color: transparent !important;
  color: white;
  outline: none;
}

.bt_trans_bo_white::placeholder {
  color: transparentize(white, 0.5);
  opacity: 1; /* Firefox */
}

.bt_trans_bo_white:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: transparentize(white, 0.5);
}

.bt_trans_bo_white::-ms-input-placeholder {
  /* Microsoft Edge */
  color: transparentize(white, 0.5);
}

.inputrow {
  .inputerror {
    color: $cl_red;
    text-align: left;
    padding: 1px 2px;
  }
  .link_texts a {
    color: white;
    text-decoration: underline;
  }
}

.accentbox {
  div {
    color: white;
  }
  background-color: $cl_accent;
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
  .inputerror {
    color: #ffc6c6;
  }
}

.code_box {
  div {
    color: white;
  }
  background-color: $cl_bg;
  border-radius: 5px;
  overflow: hidden;
  //box-shadow: 0px 0px 3px transparentize($cl_accent, 0.5);
  .inputerror {
    color: #ffc6c6;
  }
}

.table_dark {
  th {
    background-color: $cl_accent;
    background-color: $cl_code_l;
    color: white;
  }
  td {
    background-color: $cl_code;
    color: white;
  }
  td,
  th {
    border: solid 1px $cl_code_l;
    padding: 8px;
    min-height: 100px;
  }
}

.table_light {
  th {
    background-color: $cl_accent;
    background-color: $cl_code_l;
    border: solid 1px lighten($cl_code, 20%);
    color: white;
  }
  td {
    //background-color: $cl_code;
    background-color: white;
    border: solid 1px lighten($cl_code, 65%);
  }
  td,
  th {
    padding: 8px;
    min-height: 100px;
  }
  td:first-child {
    font-weight: bold;
  }
}

.table_trans {
  th {
    font-weight: bold;
    border-bottom: solid 4px $cl_accent;
  }
  td {
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
  }
  td,
  th {
    padding: 8px 15px;
  }
}

.padding_level0 {
  padding-left: 0px;
}
.padding_level1 {
  padding-left: 15px;
}
.padding_level2 {
  padding-left: 30px;
}
.padding_level3 {
  padding-left: 45px;
}
.padding_level4 {
  padding-left: 60px;
}
.padding_level5 {
  padding-left: 75px;
}
.padding_level6 {
  padding-left: 90px;
}
.padding_level7 {
  padding-left: 100px;
}
.padding_level8 {
  padding-left: 110px;
}
.padding_level9 {
  padding-left: 120px;
}
.padding_level10 {
  padding-left: 130px;
}
